import {MINIO_SERVER_URLS, SERVER_URLS} from '@env/baseURL';
import * as packageJson from 'package.json';

const packageVersion = JSON.stringify(packageJson);

export const environment = {
  apiPort: 0,
  apiUrl: SERVER_URLS.pqNet2ApiDevR9,
  appName: 'ProquifaNet 2',
  appVersion: JSON.parse(packageVersion)?.version,
  environmentServerName: 'cloudflare-dev-r9',
  i18nPrefix: '',
  identityPort: 9002,
  identityUrl: SERVER_URLS.pqNet2IdentityDevR9,
  production: false,
};

export const minioSettings = {
  accessKey: 'OVSz7lUUKRbetogm',
  host: MINIO_SERVER_URLS.pqNet2DevR9,
  port: 9000,
  secretKey: 'TAzWH3B8otqSSPFCQYDhclFByBxTomvG',
  useSSL: true,
};
